// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import noop from 'lodash/noop';
import Blanket from '@atlaskit/blanket';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics';
import {
	MODAL_ID,
	RENAME_OR_SHARE_DASHBOARD_EXPERIENCE,
	RENAME_OR_SHARE_DASHBOARD,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type EditDashboardModal from '@atlassian/jira-edit-dashboard-modal';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import { lazy } from '@atlassian/react-loosely-lazy';
import { useEditDashboard } from '../../../../../controllers/edit-dashboard';
import { handleFetchErrorAsExperience } from '../../../../../utils/handle-fetch-error-as-experience/index.tsx';
import { logErrorEventFunc } from './utils';

type OnSuccessType = ComponentProps<typeof EditDashboardModal>['onSuccess'];

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyRenameOrShareModal = lazy<typeof EditDashboardModal>(
	() =>
		import(
			/* webpackChunkName: "async-dashboard-rename-or-share-dashboard-modal" */ '@atlassian/jira-edit-dashboard-modal'
		),
);

const expAttributes = {
	analyticsSource: 'dashboard',
	experience: RENAME_OR_SHARE_DASHBOARD_EXPERIENCE,
	application: null,
	edition: null,
	additionalAttributes: {
		isCancel: false,
	},
} as const;

const sendSuccessExperience = () =>
	sendExperienceAnalytics({
		...expAttributes,
		wasExperienceSuccesful: true,
	});

const sendFailedExperience = () =>
	sendExperienceAnalytics({
		...expAttributes,
		wasExperienceSuccesful: false,
	});

export const RenameOrShareModalInner = ({
	id,
	onModalCloseCallback,
}: {
	id: string;
	onModalCloseCallback?: () => void;
}) => {
	const [isOpen, { off: close }] = useSwitchModals(MODAL_ID.DASHBOARD_RENAME_OR_SHARE);
	const onEditDashboard = useEditDashboard();

	const onSuccess: OnSuccessType = (_, response) => {
		onEditDashboard(response);
		sendSuccessExperience();
		close();
		onModalCloseCallback?.();
	};

	const onInitiateFailed = (error: Error) => {
		handleFetchErrorAsExperience({
			error: error || new Error('rename or share dashboard modal initialisation failed'),
			sendSuccessExperience,
			sendFailedExperience,
			logErrorEvent: logErrorEventFunc(error, RENAME_OR_SHARE_DASHBOARD),
		});
		close();
		onModalCloseCallback?.();
	};

	const onFailed = (error: Error) => {
		handleFetchErrorAsExperience({
			error,
			sendSuccessExperience,
			sendFailedExperience,
			logErrorEvent: logErrorEventFunc(error, RENAME_OR_SHARE_DASHBOARD),
		});
		close();
		onModalCloseCallback?.();
	};

	const onCancel = () => {
		sendExperienceAnalytics({
			...expAttributes,
			wasExperienceSuccesful: true,
			additionalAttributes: {
				isCancel: true,
			},
		});
		close();
		onModalCloseCallback?.();
	};

	return isOpen ? (
		<Placeholder name="rename-or-share-modal" fallback={<Blanket />}>
			<LazyRenameOrShareModal
				onInitiateFailed={onInitiateFailed}
				onInitiateSuccess={noop}
				onSuccess={onSuccess}
				onCancel={onCancel}
				onFailed={onFailed}
				id={id}
			/>
		</Placeholder>
	) : null;
};

export const RenameOrShareModal = (props: { id: string; onModalCloseCallback?: () => void }) => (
	<JSErrorBoundary
		id={RENAME_OR_SHARE_DASHBOARD_EXPERIENCE}
		packageName="dashboard"
		fallback="flag"
		attributes={{ task: RENAME_OR_SHARE_DASHBOARD_EXPERIENCE }}
		withExperienceTracker
	>
		<RenameOrShareModalInner {...props} />
	</JSErrorBoundary>
);
